import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <div className="">
      <p>I’m Charles Kihe, a design-focused web developer living in Hawai‘i.</p>
      <p>
        For over a decade and a half, I’ve worked on a bunch of web sites and
        applications.
        <br />
        Some of them won awards.
      </p>
      <p>
        As former senior developer at{" "}
        <a href="https://www.walltowall.com">Wall-to-Wall Studios</a>, I’ve
        worked on clients such as (in no particular order):
      </p>
      <ul>
        <li>
          <a
            href="https://www.walltowall.com/pages/surfjack"
            target="_blank"
            rel="noopener noreferrer"
          >
            The Surfjack
          </a>
        </li>
        <li>
          <a
            href="https://www.walltowall.com/pages/locations"
            target="_blank"
            rel="noopener noreferrer"
          >
            Locations
          </a>
        </li>
        <li>
          <a
            href="https://www.walltowall.com/pages/outrigger"
            target="_blank"
            rel="noopener noreferrer"
          >
            Outrigger Resorts
          </a>
        </li>
        <li>
          <a
            href="https://www.walltowall.com/pages/hawaii-energy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hawaii Energy
          </a>
        </li>
        <li>
          <a
            href="https://www.walltowall.com/pages/hawaii-community-foundation"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hawaii Community Foundation
          </a>
        </li>
        <li>
          <a
            href="https://www.walltowall.com/pages/ilikai"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ilikai
          </a>
        </li>
        <li>
          <a
            href="https://www.walltowall.com/pages/indian-head-camp"
            target="_blank"
            rel="noopener noreferrer"
          >
            Indian Head Camp
          </a>
        </li>
        <li>
          <a
            href="https://www.walltowall.com/pages/l-l-hawaiian-barbecue"
            target="_blank"
            rel="noopener noreferrer"
          >
            L&amp;L Hawaiian Barbecue
          </a>
        </li>
        <li>
          <a
            href="https://www.walltowall.com/pages/hawaii-national-bank"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hawaii National Bank
          </a>
        </li>
        <li>
          <a
            href="https://www.walltowall.com/pages/omidyar-fellows"
            target="_blank"
            rel="noopener noreferrer"
          >
            Omidyar Fellows
          </a>
        </li>
        <li>
          <a
            href="https://www.walltowall.com/pages/kahala-mall"
            target="_blank"
            rel="noopener noreferrer"
          >
            Kahala Mall
          </a>
        </li>
        <li>
          <a
            href="https://www.walltowall.com/pages/leather-soul"
            target="_blank"
            rel="noopener noreferrer"
          >
            Leather Soul
          </a>
        </li>
        <li>
          <a
            href="https://www.walltowall.com/pages/chaminade"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chaminade University
          </a>
        </li>
        <li>
          <a
            href="https://www.walltowall.com/pages/pacific-islanders-in-communications"
            target="_blank"
            rel="noopener noreferrer"
          >
            Pacific Islanders in Communications
          </a>
        </li>
      </ul>
      <p>... and a bunch more.</p>
      <p>
        I currently work at{" "}
        <a
          href="https://wealthfit.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          WealthFit
        </a>
        , an awesome platform for financial education.
      </p>
      <p>
        I’m also currently freelancing. Got some web thing that needs to be
        done? Let’s talk :)
      </p>
      <p>charles@simplesessions.com</p>
      <p>
        <a
          href="https://www.linkedin.com/in/charleskihe/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
      </p>
    </div>
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Simple Sessions - Web Development and Design in Hawaii" />

export default IndexPage
